import React from 'react'
import styles from './styles.module.scss'
import { FacebookShareButton } from 'react-share'
import { Facebook } from '../Icon'
import { useTranslation } from 'react-i18next/'

interface SocialMediaShareProps {
  socialConfig: any
  results: any
}

const Share: React.FC<SocialMediaShareProps> = ({ socialConfig, results }) => {
  const { i18n } = useTranslation()

  return (
    <div className={styles.postSocial}>
      <FacebookShareButton className={styles.button} url={socialConfig.config.url} quote={i18n.t('checkout.share', { results })}>
        <Facebook />
      </FacebookShareButton>
    </div>
  )
}

export default Share
