import React, { useContext } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styles from './styles.module.scss'
import logo from '../../assets/svg/klimatbutiken_logo.svg'
import { Link as GatsbyLink } from 'gatsby'

import SocMedShare from '../../components/SocMedShare/SocMedShare'
import Lottie from '../../components/Lottie/Lottie'
import i18next from 'i18next'
import { StoreContext } from '../../store/context'

interface SuccessAcf {
  title_success: string
  title_error: string
  text: string
  text_error: string
  offer: string
  cta: string
  bg_image: {
    source_url: string
    media_details: {
      sizes: {
        medium: {
          source_url: string
        }
        large: {
          source_url: string
        }
      }
    }
  }
  modal_image: {
    source_url: string
    media_details: {
      sizes: {
        medium: {
          source_url: string
        }
      }
    }
  }
}

interface StaticQueryProps {
  allWordpressPage: {
    nodes: Array<{
      acf: {
        success_screen: SuccessAcf
      }
    }>
  }
}

const query = graphql`
  query SuccessScreenQuery {
    allWordpressPage(filter: { slug: { eq: "screens" } }) {
      nodes {
        acf {
          success_screen {
            title_success
            title_error
            text
            text_error
            offer
            cta
            bg_image {
              source_url
              media_details {
                sizes {
                  medium {
                    source_url
                  }
                  large {
                    source_url
                  }
                }
              }
            }
            modal_image {
              source_url
              media_details {
                sizes {
                  medium {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const phoneNumber = (value: any) => (value && !/^\+?\d*$/i.test(value) ? 'PHONE_NUMBER' : undefined)

const SuccessScreen: React.FC = () => {
  // const [showModal, setShowModal] = useState(false)

  const data: StaticQueryProps = useStaticQuery(query)
  if (!data) return null
  const fields = data.allWordpressPage.nodes[0]?.acf?.success_screen
  const { text, text_error, title_error, title_success } = fields
  const { source_url } = fields.bg_image.media_details.sizes.large
  // const { source_url } = fields.modal_image.media_details.sizes.medium.source_url

  const {
    state: { shoppingCart }
  } = useContext(StoreContext)

  const allOk = shoppingCart.totalAmount <= 19

  // const toggleModal = () => setShowModal(!showModal)

  const cartSum = shoppingCart.totalAmount.toString().replace('.', ',')

  return (
    <div
      className={styles.resultView}
      style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${source_url})` }}
    >
      <section className={styles.wrapper}>
        <GatsbyLink to="/">
          <img loading="lazy" className={styles.logo} src={logo} alt="logo" />
        </GatsbyLink>
        {allOk && (
          <div className={styles.starsWrapper}>
            <p className={styles.h1}>{i18next.t('checkout.success')}</p>
            <span className={styles.stars}>
              <Lottie animation="stars" />
            </span>
          </div>
        )}

        <h1 className={allOk ? styles.message : styles.h1}>{allOk ? title_success : title_error}</h1>
        {allOk ? (
          <p className={styles.textRegular}>{text}</p>
        ) : (
          <GatsbyLink className={styles.offerlink} to="/butiken">
            {text_error}
          </GatsbyLink>
        )}
        {allOk && (
          <>
            {/* <p className={styles.text}>{offer}</p>
            <button onClick={toggleModal} className={styles.offerlink}>
              {cta}
            </button> */}

            <div className={styles.socShareWrapper}>
              <SocMedShare
                socialConfig={{
                  config: {
                    url: `https://klimatbutiken.com/`,
                    title: 'Felix Klimatbutiken',
                    quote: 'Handla klimatsmart'
                  }
                }}
                results={cartSum}
              />
            </div>
          </>
        )}
      </section>
      {/* <Modal
        className={styles.formModal}
        open={showModal}
        onClose={toggleModal}
        backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${fields.modal_image.media_details.sizes.medium.source_url})`}
      >
        <FormModal />
      </Modal> */}
    </div>
  )
}

export default SuccessScreen
