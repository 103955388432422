import React from 'react'
import { Helmet } from 'react-helmet'
import SuccessScreen from '../screens/ResultScreen/ResultScreen'

const ResultPage: React.FC = () => {
  const meta = {
    title: 'Resultat | Klimatbutiken ',
    description: 'Ditt resultat'
  }
  return (
    <>
      <Helmet
        title={meta.title}
        meta={[
          {
            property: `og:url`,
            content: `https://klimatbutiken.com/resultat`
          },
          {
            property: `og:title`,
            content: `Felix Klimatbutiken! `
          },
          {
            property: `og:description`,
            content: `Handla klimatsmart!`
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:image`,
            content: `https://klimatbutiken.accomplice-dev.se/wp-content/uploads/2021/03/tomater-min.jpg`
          }
        ]}
      ></Helmet>
      <SuccessScreen />
    </>
  )
}

export default ResultPage
